import { useTranslation } from 'react-i18next';

export default function Footer() {
    const { t } = useTranslation();

    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6 mb-12">
                <div className="dark relative bg-gray-800 py-10 px-8 md:py-16 md:px-12 rounded-2xl">
                    <div className="absolute inset-0 left-auto pointer-events-none" aria-hidden="true">
                        <svg className="h-full rounded-2xl" width="400" height="232" viewBox="0 0 400 232" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="box-gr-a">
                                    <stop stopColor="#584CF4" offset="0%" />
                                    <stop stopColor="#584CF4" stopOpacity="0" offset="100%" />
                                </radialGradient>
                                <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="box-gr-b">
                                    <stop stopColor="#667EEA" offset="0%" />
                                    <stop stopColor="#667EEA" stopOpacity="0" offset="100%" />
                                </radialGradient>
                            </defs>
                            <g transform="translate(-85 -369)" fill="none" fillRule="evenodd">
                                <circle fillOpacity=".16" fill="url(#box-gr-a)" cx="413" cy="688" r="240" />
                                <circle fillOpacity=".24" fill="url(#box-gr-b)" cx="400" cy="400" r="400" />
                            </g>
                        </svg>
                    </div>
                    <div className="relative flex flex-col justify-between items-center">
                        <div className="mb-6 text-center gap-8">
                            <h3 className="h3 font-red-hat-display text-gray-100">{t('footer.title')}</h3>
                            <p className="text-xl text-gray-600 dark:text-gray-400">{t('footer.description')}</p>
                        </div>
                        <form className="w-full">
                            <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md">
                                {/*<input type="tel" className="form-input text-gray-100 w-full mb-2 sm:mb-0 sm:mr-2 rounded-2xl" placeholder={t('misc.email')} aria-label={t('misc.email')} />*/}
                                <a className="btn text-white bg-indigo-500 hover:bg-indigo-400 shrink-0 rounded-2xl" href="mailto:contact@useat.eu" target="_blank" rel="noreferrer">
                                    {t('misc.emailCta')}
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}
