import React from 'react';

import { Routes, Route } from "react-router-dom"

import RootLayout from './layout/Layout';
import Home from 'pages/Home';
import VerifyEmailCallback from 'pages/VerifyEmailCallback';

const App = () => {
    return (
        <RootLayout>
            <Routes>
                <Route path="/" element={ <Home/> }/>
                <Route path="/auth/verify-email-callback" element ={<VerifyEmailCallback/>} />
            </Routes>
        </RootLayout>
    );
};

export default App;
