import React from 'react';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { initializeApp } from "firebase/app";

import App from './App';

import 'utils/i18n';

import './styles/index.css';

// Initialize firebase
initializeApp({
    apiKey: "AIzaSyBBO2OgvY9boepjMYn1E-pxkS0okQrxaRU",
    authDomain: "useat-prod.firebaseapp.com",
    projectId: "useat-prod",
    storageBucket: "useat-prod.appspot.com",
    messagingSenderId: "322897688309",
    appId: "1:322897688309:web:6356d45c3c3c12887d77bc",
    measurementId: "G-FVW5FEGMD4"
});

// Initialize application
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
);
