import { useEffect } from 'react';

import AOS from 'aos';

import 'aos/dist/aos.css';
import useBodyClass from '../hooks/useBodyClass';

export default function RootLayout({ children }: { children: React.ReactNode }) {
    useEffect(() => {
        AOS.init({
            once: true,
            disable: 'phone',
            duration: 750,
            easing: 'ease-out-quart',
        });
    });

    useBodyClass(['font-inter', 'antialiased', 'bg-white', 'text-gray-900', 'dark:bg-gray-900', 'dark:text-gray-100', 'tracking-tight']);

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            <main className="grow">{children}</main>
        </div>
    );
}
