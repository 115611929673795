/* eslint-disable */

export default {
    intro: {
        title: 'Safe tickets for seated events',
        description: 'Throw away fraud and security issues, know your spectators and enhance their experience. All in one solution.',
        steps: {
            step1: 'Unique tickets, tied to the phone and identity. No duplication or forging',
            step2: 'Customizable content with last-mile value added services',
            step3: 'Native and frictionless integrated KYS (Know-Your-Spectator)',
            step4: 'Software solution with no change to your processes or hardware'
        },
    },
    kpi: {
        kpi1: 'Ticket fraud increase\neach year',
        kpi2: 'Fraudulent tickets\non the secondary market',
        kpi3: 'Spectators in the\nstadiums on 2022 (France)',
        kpi4: 'Average spectators\nper event in 2022 (France)',
    },
    footer: {
        title: 'Have a seat',
        description: 'Security agencies, football clubs and leagues are already joining the movement\nWhy not you?',
    },
    misc: {
        email: 'Email Address',
        emailCta: 'Get in touch',
    },
};
