
import { useSearchParams } from 'react-router-dom';

const VerifyEmailCallback = () => {
    const [searchParams] = useSearchParams();

    const claimCode = searchParams.get('claimCode');
    if(!claimCode){
        console.error('No claim code found');
        return null;
    }

    window.open(`useat-consumer://auth/verify-email-callback?claimCode=${claimCode}`, '_self');
    window.close();
    return null;
};

export default VerifyEmailCallback;