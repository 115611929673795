import React from 'react';

import Footer from "components/Footer";
import Intro from "components/Intro";
import Kpi from "components/Kpi";
import PageIllustration from "components/PageIllustration";

const Home = () => {
    return (
        <>
            <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
                <PageIllustration />
            </div>
            <Intro />
            <Kpi />
            <Footer />
        </>
    );
}

export default Home;