import { useTranslation } from 'react-i18next';

export default function Kpi() {
    const { t } = useTranslation();

    return (
        <section className="relative">
            <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pb-12 md:pb-20">
                    <div className="grid grid-cols-2 gap-4 lg:gap-6 md:grid-cols-4 text-center" data-aos-id-stats>
                        <div className="bg-white dark:bg-gray-800 py-8 px-1 shadow-2xl rounded-2xl" data-aos="fade-down" data-aos-anchor="[data-aos-id-stats]">
                            <div className="font-red-hat-display text-3xl font-black tracking-tighter mb-1">141%</div>
                            <div className="text-gray-600 dark:text-gray-400">{t('kpi.kpi1')}</div>
                        </div>
                        <div className="bg-white dark:bg-gray-800 py-8 px-1 shadow-2xl rounded-2xl" data-aos="fade-down" data-aos-anchor="[data-aos-id-stats]" data-aos-delay="100">
                            <div className="font-red-hat-display text-3xl font-black tracking-tighter mb-1">20%</div>
                            <div className="text-gray-600 dark:text-gray-400">{t('kpi.kpi2')}</div>
                        </div>
                        <div className="bg-white dark:bg-gray-800 py-8 px-1 shadow-2xl rounded-2xl" data-aos="fade-down" data-aos-anchor="[data-aos-id-stats]" data-aos-delay="200">
                            <div className="font-red-hat-display text-3xl font-black tracking-tighter mb-1">10M</div>
                            <div className="text-gray-600 dark:text-gray-400">{t('kpi.kpi3')}</div>
                        </div>
                        <div className="bg-white dark:bg-gray-800 py-8 px-1 shadow-2xl rounded-2xl" data-aos="fade-down" data-aos-anchor="[data-aos-id-stats]" data-aos-delay="300">
                            <div className="font-red-hat-display text-3xl font-black tracking-tighter mb-1">23 000</div>
                            <div className="text-gray-600 dark:text-gray-400">{t('kpi.kpi4')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
