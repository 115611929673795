/* eslint-disable */

export default {
    intro: {
        title: 'Tickets sécurisés pour évènements placés',
        description: 'Remédiez aux problèmes de fraude et de sécurité, connaissez vos spectateurs et améliorez leur expérience. Tout en une solution.',
        steps: {
            step1: 'Billets uniques, liés au téléphone et à l\'identité. Pas de duplication ni de falsification',
            step2: 'Contenu personnalisable avec des services à valeur ajoutée de dernière minute',
            step3: 'KYS (Know-Your-Spectator) intégré nativement et sans friction',
            step4: 'Solution logicielle sans changement de vos processus ou de votre matériel'
        },
    },
    kpi: {
        kpi1: 'Augmentation de la fraude aux billets chaque année',
        kpi2: 'Billets frauduleux\nsur le marché secondaire',
        kpi3: 'Spectateurs dans les\nstades en 2022 (France)',
        kpi4: 'Spectateurs moyens\npar évènement en 2022 (France)',
    },
    footer: {
        title: 'Prenez place',
        description: 'Les agences de sécurité, les clubs de football et les ligues rejoignent déjà le mouvement\nPourquoi pas vous?',
    },
    misc: {
        email: 'Adresse Email',
        emailCta: 'Contactez-nous',
    },
};
