import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { en } from 'locales';
import { fr } from 'locales';

export const resources = {
    en: {
        translation: en,
    },
    fr: {
        translation: fr,
    },
} as const;

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        resources,
        nonExplicitSupportedLngs: true,
    })
    .finally(() => null);

export default i18n;
