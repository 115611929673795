import { useTranslation } from 'react-i18next';

import phoneImage from 'assets/images/splash.png';
import ModalIphone from './ModalPhone';

export default function Intro() {
    const { t } = useTranslation();

    return (
        <section>
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="pt-16 pb-10 md:pt-24 md:pb-16">
                    <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-20 items-center">
                        <div className="md:col-span-7 lg:col-span-7 mb-8 md:mb-0 text-center md:text-left">
                            <h1 className="h1 lg:text-5xl md:text-4xl mb-4 font-red-hat-display font-black" data-aos="fade-down">
                                {t('intro.title')}
                            </h1>
                            <p className="text-xl text-gray-600 dark:text-gray-400" data-aos="fade-down" data-aos-delay="150">
                                {t('intro.description')}
                            </p>
                            <form className="mt-8" data-aos="fade-down" data-aos-delay="300">
                                <div className="flex flex-col sm:flex-row justify-left max-w-sm mx-auto sm:max-w-md md:mx-0">
                                    {/*<input type="tel" className="form-input w-full mb-2 sm:mb-0 sm:mr-2 rounded-2xl" placeholder={t('misc.email')} aria-label={t('misc.email')} />*/}
                                    <a className="btn text-white bg-indigo-500 hover:bg-indigo-400 shrink-0 rounded-2xl" href="mailto:contact@useat.eu" target="_blank" rel="noreferrer">
                                        {t('misc.emailCta')}
                                    </a>
                                </div>
                                {/* <p className="text-center md:text-left mt-2 opacity-75 text-sm">Thanks for subscribing!</p> */}
                            </form>
                            <ul className="max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-600 dark:text-gray-400 mt-8 -mb-2" data-aos="fade-down" data-aos-delay="450">
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-indigo-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>{t('intro.steps.step1')}</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-indigo-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>{t('intro.steps.step2')}</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-indigo-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>{t('intro.steps.step3')}</span>
                                </li>
                                <li className="flex items-center mb-2">
                                    <svg className="w-3 h-3 fill-current text-indigo-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                                    </svg>
                                    <span>{t('intro.steps.step4')}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="md:col-span-5 lg:col-span-5 text-center md:text-right" data-aos="fade-up" data-aos-delay="450">
                            <ModalIphone thumb={phoneImage} thumbWidth={290} thumbHeight={624} thumbAlt="Modal video thumbnail" video="/videos/video.mp4" videoWidth={1920} videoHeight={1080} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
